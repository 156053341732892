<template>
  <div class='container'>
    <!-- <div class="header">
      <div class="options">
        <div :class="['option',activeIndex===1?'active':'']" @click="tabChange(1)">
          静态测评预警
        </div>
        <div :class="['option',activeIndex===2?'active':'']" @click="tabChange(2)">
          动态观察预警
        </div>
        <div :class="['option',activeIndex===3?'active':'']" @click="tabChange(3)">
          预警解除名单
        </div>
      </div>
      <div class="search">
        <van-icon name="search" size="4.8vw"/>
        <input type="text" v-model="listQuery.trueName" placeholder="请输入标题进行查找">
        <button @click="search">搜索</button>
      </div>
    </div> -->
    <div class="static-list" v-if="list.length>0">
      <div class="total">
        <p>共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名学生存在预警</p>
        <!-- <div style="display: flex;align-items: center;" @click="isShow=true">
          <p style="font-weight: 550;margin-right: 1.3333vw;color: #000;">筛选</p>
          <img src="@/assets/project/screen.png" alt="" style="width: 6.4vw;height: 6.4vw;">
        </div> -->
      </div>
      <div class="list-content" v-for="item in list" :key="item.examId" >
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <p><span style="font-size: 4.8vw;">{{transformGrade(item.grade)}}</span>  {{item.studyClass}}</p>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 4vw;">
          <p style="color: rgba(125, 125, 125, 1);font-size: 2.9333vw;">{{item.created_at}}</p>
          <div class="btns" style="display: flex;align-items: center;">
            <button @click="$router.push({path:'classFeedback',query:{id:item.examId,grade:item.grade,studyClass:item.studyClass}})">详情</button>
            <!-- <button @click="isDialog=true">评估</button> -->
          </div>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <van-empty description="暂无班级报告" v-else></van-empty>
    <!-- <van-popup v-model="isShow" position="right" :style="{ height: '100%',width:'75%' }" close-on-click-overlay>
      <div class="popup">
        <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 6.6667vw;">
          <p style="font-size: 4.2667vw;font-weight: 550;">列表筛选</p>
          <img src="@/assets/project/close.png" alt="" style="width: 6.4vw;height: 6.4vw;" @click="isShow=false">
        </div>
        <div class="popup-list">
          <div class="option">
            <p>年级</p>
            <div class="selectInput">
              <="text" v-model="gradeName" @click="showPopup('grade')">
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <p>班级</p>
            <div class="selectInput">
              <input type="text" v-model="listQuery.studyClass" @click="showPopup('class')">
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
        </div>
        <div class="btns">
          <button @click="reset">重置</button>
          <button style="background-color: #000;" @click="filterSearch">搜索</button>
        </div>
      </div>
    </van-popup> -->
    <!-- <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="选择年级"
        value-key="studyClass"
        show-toolbar
        :columns="classList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='class'"
      />
      <van-picker
        title="选择班级"
        value-key="gradeName"
        show-toolbar
        :columns="gradeList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='grade'"
      />
    </van-popup> -->
    <van-dialog v-model="isDialog" title="温馨提示" :showConfirmButton="false">
      <div class="dialog">
        <img src="@/assets/my.png"  style="width: 53.3333vw;height: 32vw;margin: 5.3333vw 0 4vw 0;"/>
        <p>移动端暂未开放评估功能，</p>
        <p>如需对学生进行心理评估请使用PC端进行评估</p>
        <button @click="isDialog=false">我知道了</button>
      </div>
    </van-dialog>
    <!-- <Tabbar :activeIndex="2"></Tabbar> -->
  </div>
</template>

<script>
import Tabbar from '@/components/Tabbar'
import {bottomLoading} from '@/utils/util.js'
import {warningList} from '@/api/warning.js'
import {exams} from '@/api/exam.js'
import {gradeAndStudyClass} from '@/api/report.js'
import {classStatistics} from '@/api/project.js'
export default {
  components: {
    Tabbar,
  },
  data(){
    return{
      isShow:false,
      isDialog:false,
      // isPopup:false,
      list:[],
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        examId: ''
      },
      // 展示数据
      gradeList:[],
     /*  gradeName:null,
      className:null,
      examName:null,
      levelName:null,
      fromTypeName:null,
      classList:['全部'],
      examsScreenList:[],
      fromTypeTypeList:[{id:-1,title:'全部'},{id:1,title:'静态测评预警'},{id:2,title:'动态测评预警'},],
      levelList:[{id:-1,title:'全部'},{id:1,title:'一级'},{id:2,title:'二级'},{id:3,title:'三级'},], */
      recordCount:0,
      totalPageCount:0,
    }
  },
  created(){
    this.listQuery.examId=this.$route.query.id
    this.getList()
    // this.getExamsScreenList()
    this.getGradeOrClass()
  },
  mounted(){
    if(this.list.length>0){
      bottomLoading(this.$refs.bottom,this.loadNextPage)
    }
  },
  updated(){
  },
  methods:{
    async getList(){
      /* const formData={}
      for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
      }
      if(this.listQuery.warningStatus===-1){formData.warningStatus=-1}
      if(this.listQuery.studyClass==='全部'){formData.studyClass=null} */

      const res = await classStatistics(this.listQuery)
      this.list=res.data.list
      this.totalPageCount=res.data.totalPageCount
      this.recordCount=res.data.recordCount
    },
    async getGradeOrClass(){
      const res = await gradeAndStudyClass()
      res.data.grades.unshift({grade:-1,gradeName:'全部'})
      this.gradeList=res.data.grades
    },
    transformGrade(grade){
      let gradeName=''
      this.gradeList.forEach(item => {
        if(item.grade===grade){
          // console.log(339,grade,item.gradeName);
          gradeName=item.gradeName
        }
      })
      return gradeName
    },
    async loadNextPage() {
      if(this.listQuery.pageNum<this.totalPageCount){
        this.listQuery.pageNum++
        this.listQuery.pageNo++
        const formData={}
        for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
        }
        if(this.listQuery.warningStatus===-1){formData.warningStatus=-1}
        const res = await warningList(formData)
        this.totalPageCount=res.data.totalPageCount
        this.recordCount=res.data.recordCount
        this.list.push(...res.data.list)
      }
      // 在这里编写请求下一页数据的逻辑
      // 可以使用AJAX或其他方式向服务器发送请求
      // 将获取的数据添加到this.list数组中
      // 更新this.page变量为下一页的页码
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 13.3333vw 3.2vw;
  background-color: #fff;
  min-height: 100%;
  .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4vw 0;
    color: rgba(149, 149, 149, 1);
  }
  .static-list{
    .list-content{
      margin-bottom: 5.3333vw;
      padding: 4vw;
      border-radius: 2.6667vw;
      background-color: #fff;
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      .btns{
        button{
          padding: 1.3333vw 2.6667vw;
          font-size: 3.2vw;
          border-radius: 1.0667vw;
          // border: .2667vw solid rgba(0, 0, 0,1);
          margin-right: 1.6vw;
          color: #fff;
          background: #5EDBC9;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .time{
        display: flex;
        justify-content: flex-start;
        p{
          font-size: 2.9333vw;
          padding: 1.3333vw 4vw;
          color: rgba(125, 125, 125, 1);
          border: .2667vw solid rgba(217, 217, 217, 1);
          border-radius: 1.0667vw;
          background-color: rgba(245, 245, 245, 1);
        }
      }
      .status{
        margin-top: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
          padding: 1.3333vw 4vw;
          font-size: 3.2vw;
          border-radius: 1.3333vw;
          border: .2667vw solid rgb(0, 0, 0);
          background-color: #fff;
          margin-right: 1.6vw;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  .popup{
    position: relative;
    padding: 5.8667vw 5.3333vw 13.8667vw 5.3333vw;
    .popup-list{
      height: 80vh;
      overflow: scroll;
      width: 100%;
      // margin-top: 6.6667vw;
      .option{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6667vw;
        color: #333;
        .selectInput{
          position: relative;
          input{
            padding-right: 9.3333vw;
            width: 100%;
          }
          .arrow{
            position: absolute;
            top: 53%;
            right:2.4vw;
            transform: translateY(-50%);
          }
        }
        input{
          margin-top:1.3333vw ;
          padding: 0 2.6667vw;
          height: 11.4667vw;
          border: .2667vw solid rgba(187, 187, 187, 1);
        }
        p{
          font-weight: 550;
        }
      }
    }
    .btns{
      position: fixed;
      display: flex;
      align-items: center;
      width: 100%;
      left: 0;
      bottom: 0;
      button{
        flex: 1;
        font-size: 4.2667vw;
        color: #fff;
        height: 13.8667vw;
        background-color: rgba(161, 161, 161, 1);
      }
    }
  }
  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.4667vw;
    p{
      line-height: 6.4vw;
    }
    button{
      margin: 8vw;
      width: 45.3333vw;
      height: 11.2vw;
      color: #fff;
      line-height: 11.2vw;
      text-align: center;
      border-radius: 13.3333vw;
      background-color: #000;
    }
  }
}
</style>